<template>
    <b-modal
      id="send-sms-modal"
      centered
      size="md"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <template #modal-title> <h2 class="m-0">Enter Message</h2> </template>
      <validation-observer ref="sendSMSValidation">
        <b-form @submit.prevent>
          <b-form-row v-if="allowNumber">
            <b-col cols="12" class="px-2 pt-2">
              <b-form-group>
                  <validation-provider #default="{ errors }" name="number"
                      :rules="{ required: allowNumber, regex: /^[0-9-+]*$/, length: 11 }">
                      <b-form-input id="number" v-model="number" :state="errors.length > 0 ? false : null"
                      placeholder="Please enter a number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-form-row>        
          <b-form-row>
            <b-col cols="12" class="px-2 py-2">
                <validation-provider
                    #default="{ errors }"
                    name="Message"
                    rules="required"
                  >
                    <b-form-textarea
                      id="message"
                      v-model="message"
                      :state="errors.length > 0 ? false : null"
                      name="message"
                      size="lg"
                      placeholder="Please enter your message."
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-col>
          </b-form-row>
          <b-form-row v-if="message">
              <div class="ml-auto mx-2">
                 <b-button
                  variant="success"
                  size="md"
                  class="text-truncate  mb-1"
                  @click="validationForm"
                >
                  Send
                </b-button>
              </div>
          </b-form-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
  import { required } from "@validations";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueSelectPaginated,
    },
    mixins: [util, togglePasswordVisibility],
      props: {
      user: Object,
      allowNumber: Boolean,
    },
    data() {
      return {
        show : false,
        required,
        message: '',
        number: '',
      };
    },
    async mounted() {
    },
    methods: {
        ...mapActions({
        sendSMS: "appData/sendSMS",
      }),
      async validationForm() {
        try {
          const success = await this.$refs.sendSMSValidation.validate();
          if (success) {
            await this.sendMessage();
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      async sendMessage(){
                try {
                    let dataToInsert = {
                        message: this.message,
                    };
                    if(this.allowNumber){
                      dataToInsert['number'] = this.number
                    }
                    else{
                      dataToInsert['number'] = this.user.mobile
                    }
                    const res = await this.sendSMS(dataToInsert);
                    if (res.status === 200) {
                        this.$swal({
                            title: "SMS Sent",
                            icon: "success",
                        });
                        this.$nextTick(() => {
                            this.$bvModal.hide("send-sms-modal");
                        });
                        this.$emit("modalClosed");
                    }
                } catch (error) {
                    this.displayError(error);
                }
            },
      reset() {
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
        getSystemTime: "appData/getSystemTime",
      }),
    },
    // watch: {
    //   }
  };
  </script>
  
  <style></style>
  