<template>
    <div>
        <b-card class="text-black">
            <b-form>
                <b-row class="mb-1" align-v="center">
                    <b-col cols="3">
                        <b-form-group label="Name" label-for="name">
                            <template #label>
                                <h6 class="font-weight-bold">Name</h6>
                            </template>

                            <b-form-input id="name"
                                          v-model="name"
                                          placeholder="Search name" />

                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="CNIC" label-for="cnic">
                            <template #label>
                                <h6 class="font-weight-bold">CNIC</h6>
                            </template>

                            <b-form-input id="cnic"
                                          v-model="cnic"
                                          placeholder="Search CNIC" />

                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Mobile" label-for="mobile">
                            <template #label>
                                <h6 class="font-weight-bold">Mobile</h6>
                            </template>

                            <b-form-input id="mobile"
                                          v-model="mobile"
                                          placeholder="Search mobile" />

                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group>
                            <template #label>
                                <h6 class="font-weight-bold">User Type</h6>
                            </template>
                            <v-select id="user_type"
                                      v-model="user_type"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="user_types"
                                      placeholder="Select User Type"
                                      label="name"
                                      class="text-black" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <b-form-group>
                            <template #label>
                                <h6 class="font-weight-bold">Only Blocked Users</h6>
                            </template>
                            <b-form-checkbox
                                id="onlyBlocked"
                                v-model="onlyBlocked"
                                name="onlyBlocked"
                                class="ml-5"
                            >
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right mb-1">
                        <b-button variant="info" pill @click="showSMSModalWithNumberField()" class="mr-2">
                            Send SMS
                        </b-button>
                        <b-button variant="info" pill @click="clearFilter()" class="mr-2">
                            Clear Search
                        </b-button>
                        <b-button variant="primary"
                                  pill
                                  style="margin-right: 2%"
                                  @click="searchUsersData">
                            <feather-icon icon="SearchIcon" class="mr-50" />
                            Search
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <b-table responsive="sm"
                     :fields="fields"
                     sticky-header="1500px"
                     :items="users"
                     :paginated="true"
                     details-td-class="m-0 p-0"
                     small>
                <template #cell(user_type)="row">
                    {{ getUserTypeValue(row.item.user_type)}}
                </template>
                <template #cell(bms_blocked)="row">
                    {{ row.item.bms_blocked ? 'Yes' : "No" }}
                </template>
                <template #cell(bms_blocked_reason)="row">
                    <span
                        @click="showFullText(row.item.bms_blocked_reason)"
                        style="cursor: pointer !important;"
                    >
                        {{ row.value }}
                    </span>
                </template>
                <template #cell(manage)="row">
                    <b-button variant="info"
                              pill
                              size="sm"
                              class="mr-50"
                              @click="blockUser(row.item)"
                              v-if="hasPermission('user_update') && !row.item.bms_blocked">
                        Block
                    </b-button>
                    <b-button variant="danger"
                              pill
                              size="sm"
                              @click="unblockUser(row.item)"
                              v-if="hasPermission('user_update') && row.item.bms_blocked">
                        Unblock
                    </b-button>
                    <b-button variant="info"
                              pill
                              size="sm"
                              class="mr-50"
                              @click="showSMSModal(row.item)">
                        Send SMS
                    </b-button>
                </template>
            </b-table>
            <b-row>
                <b-col md="10">
                    <b-pagination :total-rows="totalDataLength"
                                  :per-page="per_page"
                                  align="center"
                                  v-model="currentPage" />
                </b-col>
                <b-col md="2">
                    <div style="float:right">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
        </b-card>
       <b-modal id="blockUserModal" v-model="showBlockUserModal" title="Block User" centered hide-footer>
            <b-form @submit.prevent="handleBlockUser">
                <b-form-group label="Block Reason" label-for="block-reason">
                    <b-form-textarea id="block-reason" v-model="blockReason" rows="6" :maxlength="500" ></b-form-textarea>
                </b-form-group>
                <b-form-group>
                    <b-button type="submit" variant="danger">Confirm</b-button>
                    <b-button variant="outline-secondary" class="ml-2" @click="showBlockUserModal = false">Cancel</b-button>
                </b-form-group>
            </b-form>
        </b-modal>
        <b-overlay 
            :show="show"
            spinner-variant="primary"
            no-wrap
         />
         <SendSMSModal
            :user="user"
            :allowNumber="allowSMSNumberInput"
            @modalClosed="onModalClosed"
            :showModal="visible"
            :key="`sms-modal-${smsModalCount}`"
        />
        <b-modal
        :id="modalId"
        :title="modalTitle"
        size="lg"
        centered
        hide-footer
        @hidden="resetModal"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        >
            <div class="modal-content">
                <p>{{ modalText }}</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required } from "@validations";
    import util from "@/util.js";
    import {VBTooltip, BButton, BCardText,} from 'bootstrap-vue'
    import SendSMSModal from "@/components/booking/admin/SendSMSModal.vue";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BButton, BCardText,
            SendSMSModal
        },
        data() {
            return {
                visible: false,
                fields: [
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "CNIC" },
                    { key: "mobile", label: "Mobile" },
                    { key: "user_type", label: "User Type" },
                    { key: "bms_blocked", label: "Is Blocked" },
                    { key: "bms_blocked_reason", label: "Block Reason" },
                    { key: "manage", label: "Manage" },
                ],
                currentPage: 1,
                totalDataLength: 0,
                per_page: 0,
                users: [],
                user: null,
                cnic: null,
                mobile: null,
                user_types: [
                    { name: "SERVING", value: 1 },
                    { name: "RETIRED", value: 2 },
                    { name: "CIVILIAN", value: 3 },],
                user_type: null,
                name: null,
                show: false,
                onlyBlocked: false,
                blockReason: '',
                showBlockUserModal: false,
                smsModalCount: 0,
                modalId: "full-text-modal",
                modalTitle: "",
                modalText: "",
                allowSMSNumberInput: false,
            };
        },
        mixins: [util],
        created() {
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
        },
        destroyed() {
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
        },
        async mounted() {
            try{
                await this.searchUsersData();
                } 
                catch (error) {
                this.displayError(error);
            }
        },
        methods: {
            ...mapActions({
                updateAfpUser: "appData/updateAfpUser",
                getAfpUsers: "appData/getAfpUsers",
            }),
            async searchUsersData() {
                try {
                    this.show = true;
                    const res = await this.getAfpUsers({
                        pageNumber: this.currentPage,
                        name: this.name,
                        cnic: this.cnic,
                        mobile: this.mobile,
                        role_code_name: "nu",
                        user_type: this.user_type ? this.user_type.value : null,
                        bms_blocked: this.onlyBlocked,
                    });
                    this.users = res.data.results;
                    this.per_page = res.data.per_page;
                    this.totalDataLength = res.data.count;
                    this.totalPageCount = res.data.total_pages;
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            async handleBlockUser() {
                if (!this.blockReason.trim()) {
                    this.$swal({
                        title: "Error",
                        text: "Block reason is required.",
                        icon: "error"
                    });
                    return;
                }

                this.showBlockUserModal = false; 
                
                try {
                    let dataToInsert = {
                        bms_blocked: true,
                        updated_by: this.getLoggedInUser.id,
                        bms_blocked_reason: this.blockReason
                    };
                    const res = await this.updateAfpUser({
                        payload: dataToInsert,
                        pk: this.currentUser.id, 
                    });
                    if (res.status === 200) {
                        this.$swal({
                            title: "User blocked",
                            icon: "success",
                        });
                        this.$nextTick(() => {
                            this.searchUsersData();
                        });
                    }
                } catch (error) {
                    this.displayError(error);
                }
            },

            blockUser(user) {
                this.currentUser = user;
                this.showBlockUserModal = true;
                this.blockReason = '';
            },
            async unblockUser(user){
                this.$swal({
                    title:
                    "Are you sure?",
                    icon: "warning",
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Confirm",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        try {
                            let dataToInsert = {
                                bms_blocked: false,
                                updated_by: this.getLoggedInUser.id,
                                bms_blocked_reason: null
                            };
                            const res = await this.updateAfpUser({
                                payload: dataToInsert,
                                pk: user.id,
                            });
                            if (res.status === 200) {
                                this.$swal({
                                    title: "User unblocked",
                                    icon: "success",
                                });
                                this.$nextTick(() => {
                                this.searchUsersData();
                                });
                            }
                        } catch (error) {
                            this.displayError(error);
                        }
                    } 
                });
            },
            async showSMSModal(user){
                this.user = user
                this.smsModalCount += 1;
                this.visible = true;
                this.allowSMSNumberInput = false;
                this.$nextTick(() => {
                    this.$bvModal.show("send-sms-modal");
                });
            },
            async showSMSModalWithNumberField(user){
                this.user = user
                this.smsModalCount += 1;
                this.visible = true;
                this.allowSMSNumberInput = true;
                this.$nextTick(() => {
                    this.$bvModal.show("send-sms-modal");
                });
            },
            showFullText(text) {
                this.modalTitle = "Block Reason";
                this.modalText = text;
                this.$bvModal.show(this.modalId);
            },
            async clearFilter() {
                this.name = null;
                this.cnic = null;
                this.mobile = null;
                this.user_type = null;
                await this.searchUsersData();
            },
            async onModalClosed() {
                await this.searchUsersData();
            },
            resetModal() {
                this.modalTitle = "";
                this.modalText = "";
            },
        },
        computed: {
            ...mapGetters({
                hasPermission: "appData/hasPermission",
                getLoggedInUser: "appData/getUser",
                hasRole: "appData/hasRole",
            }),
        },
        watch: {
            currentPage: {
                handler: async function (value) {
                    await this.searchUsersData();
                },
            },
        },
    };
</script>

<style>
    .text-black {
        color: black !important;
        /* font-size: 1.2rem; */
    }
</style>
